import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'
import Plaatjie from '@ubo/plaatjie'

// Images
import Chevron from 'img/chevron.inline.svg'
import NavLink from 'components/shared/NavLink'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 36px;
    line-height: 44px;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.latestBlogQueryQuery>(graphql`
    query latestBlogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 mb-4 mb-sm-0">
            <Content content={fields.description} />
          </div>
          <div className="col-sm-6 d-flex justify-content-end align-items-end">
            <AnchorPrimary href={fields.link?.url || '#'}>
              {fields.link?.title}
            </AnchorPrimary>
          </div>
        </div>
        <BlogBink
          posts={posts as BlogBinkPosts}
          categories={allWpCategory.edges}
          id="latest-blog"
          limit={Number(fields.limit)}
        >
          <BlogGrid fields={fields} />
        </BlogBink>
      </div>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="row">
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <div key={post.node.id} className="col-md-6 col-lg-4 mt-5 h-100">
            <BlogGridPost node={node} fields={fields} />
          </div>
        )
      })}
    </div>
  )
}

const Recap = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @media (min-width: 576px) {
      font-size: 22px;
      min-height: 80px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
    }
  }

  & svg {
    width: 14.5px;
    height: 22.5px;
  }
`

const Image = styled(Plaatjie)`
  height: 280px;
`

const Category = styled.div`
  top: 20px;
  left: 20px;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.light};
`

const Excerpt = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    @media (max-width: 575px) {
      -webkit-line-clamp: 2;
    }
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPostTypes
  node: any
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const category = node.categories.nodes[0]

  const [hovered, setHovered] = useState(false)

  return (
    <Recap className="h-100 mx-lg-1">
      <NavLink to={node.uri} className="position-relative h-100">
        <motion.div
          className="h-100"
          onHoverStart={() => setHovered(true)}
          onHoverEnd={() => setHovered(false)}
        >
          <Category className="position-absolute px-5 py-2">
            {category.name}
          </Category>
          <div className="h-50">
            <Image
              image={node.recap.thumbnail}
              className="w-100 h-100"
              alt=""
            />
          </div>
          <div className="d-flex flex-column align-items-between justify-content-between p-3 p-lg-4 h-50">
            <h2 className="mb-3">{node.title}</h2>
            <Excerpt content={node.recap.excerpt} className="mb-xl-4 pb-4" />
            <div className="d-flex justify-content-end mt-auto">
              <motion.div animate={hovered ? { x: 10 } : { x: 0 }}>
                <Chevron />
              </motion.div>
            </div>
          </div>
        </motion.div>
      </NavLink>
    </Recap>
  )
}

export default Blog
